import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import HmkIcon from '../icons/HmkIcon'
import JhtIcon from '../icons/JhtIcon'
import MimIcon from '../icons/MimIcon'
import MkxIcon from '../icons/MkxIcon'
import MktIcon from '../icons/MktIcon'
import MiwIcon from '../icons/MiwIcon'

let keepState = ''
let keepPage = ''

class ScheduleList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      toggle: false,
      text: 'change style to list',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      bleudetokyo: true,
      noMatch: false,
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (data.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
      this.setState({
        page: 1,
      })
      keepPage = ''
    }
    if (keepState !== '') {
      this[keepState](data)
    }
    if (keepPage !== '') {
      this.setState({
        page: keepPage,
      })
    }
  }

  handleChange = event => {
    const {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      bleudetokyo,
    } = this.state
    const { data } = this.props
    const name = event.currentTarget.value
    const checked = event.target.checked
    this.setState({
      current: 'all',
      [name]: checked,
    })
    const selectedCategory = {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      bleudetokyo,
      [name]: checked,
    }

    Object.keys(selectedCategory).forEach(key => {
      if (selectedCategory[key] === false) delete selectedCategory[key]
    })
    const categories = Object.keys(selectedCategory)

    const matchData = e => {
      const targetArrays = [...categories, ...e.areaCategory]
      const match = targetArrays.filter(
        item => categories.includes(item) && e.areaCategory.includes(item)
      )
      return match.length
    }

    const selectedData = data.filter(e => matchData(e) !== 0)

    // カテゴリー一個に対してだけなら下記でもOK
    // const selectedData = data.filter(
    //   e => categories.indexOf(e.areaCategory[0]) !== -1
    // )
    if (selectedData.length === 0) {
      this.setState({ noMatch: true })
    } else {
      this.setState({
        stateData: selectedData,
        noMatch: false,
      })
    }
  }

  toggleStyle = () => {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
    } else {
      this.setState({
        text: 'change style to table',
      })
    }
  }

  removeAllCheck = () => {
    this.setState({
      midkniteeuro: false,
      midkniteusa: false,
      midkniteasia: false,
      midknitepacific: false,
      midknitelatin: false,
      midkniteafrica: false,
      midknitejapan: false,
      midkniteblue: false,
      bleudetokyo: false,
      noMatch: true,
    })
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      bleudetokyo: true,
      noMatch: false,
    })
  }

  all(data) {
    this.stateInitialize()
    this.setState({ stateData: data })
  }

  international(data) {
    this.stateInitialize()
    const international = data.filter(
      e => e.parentCategory[0] === 'international'
    )
    this.setState({ stateData: international, current: 'international' })
  }

  domestic(data) {
    this.stateInitialize()
    const domestic = data.filter(e => e.parentCategory[0] === 'domestic')
    this.setState({ stateData: domestic, current: 'domestic' })
  }

  event(data) {
    this.stateInitialize()
    const event = data.filter(e => e.parentCategory[0] === 'event')
    this.setState({ stateData: event, current: 'event' })
  }

  midkniteathletics(data) {
    this.stateInitialize()
    const midkniteathletics = data.filter(
      e => e.brandCategory && e.brandCategory.includes('midkniteathletics')
    )
    this.setState({
      stateData: midkniteathletics,
      current: 'midkniteathletics',
    })
  }

  midknitetreks(data) {
    this.stateInitialize()
    const midknitetreks = data.filter(
      e => e.brandCategory && e.brandCategory.includes('midknitetreks')
    )
    this.setState({ stateData: midknitetreks, current: 'midknitetreks' })
  }

  humankind(data) {
    this.stateInitialize()
    const humankind = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'humankind'
    )
    this.setState({ stateData: humankind, current: 'humankind' })
  }

  madeinworld(data) {
    this.stateInitialize()
    const madeinworld = data.filter(
      e => e.brandCategory && e.brandCategory.includes('madeinworld')
    )
    this.setState({ stateData: madeinworld, current: 'madeinworld' })
  }

  country(data, countryName) {
    this.stateInitialize()
    const country = data.filter(
      e => e.countryCategory && e.countryCategory.includes(countryName)
    )
    this.setState({ stateData: country, current: countryName })
  }

  jht(data) {
    this.stateInitialize()
    const jht = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'jht'
    )
    this.setState({ stateData: jht, current: 'jht' })
  }

  madeinmejiro(data) {
    this.stateInitialize()
    const madeinmejiro = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'madeinmejiro'
    )
    this.setState({ stateData: madeinmejiro, current: 'madeinmejiro' })
  }

  newmankind(data) {
    this.stateInitialize()
    const newmankind = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'newmankind'
    )
    this.setState({ stateData: newmankind, current: 'newmankind' })
  }

  marinday(data) {
    this.stateInitialize()
    const marinday = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'marinday'
    )
    this.setState({ stateData: marinday, current: 'marinday' })
  }

  nanione(data) {
    this.stateInitialize()
    const nanione = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'nanione'
    )
    this.setState({ stateData: nanione, current: 'nanione' })
  }

  midkniteeuro(data) {
    this.stateInitialize()
    const midkniteeuro = data.filter(
      e => e.areaCategory.indexOf('midkniteeuro') !== -1
    )
    this.setState({ stateData: midkniteeuro, current: 'midkniteeuro' })
  }

  midkniteusa(data) {
    this.stateInitialize()
    const midkniteusa = data.filter(
      e => e.areaCategory.indexOf('midkniteusa') !== -1
    )
    this.setState({ stateData: midkniteusa, current: 'midkniteusa' })
  }

  midkniteasia(data) {
    this.stateInitialize()
    const midkniteasia = data.filter(
      e => e.areaCategory.indexOf('midkniteasia') !== -1
    )
    this.setState({ stateData: midkniteasia, current: 'midkniteasia' })
  }

  midknitepacific(data) {
    this.stateInitialize()
    const midknitepacific = data.filter(
      e => e.areaCategory.indexOf('midknitepacific') !== -1
    )
    this.setState({ stateData: midknitepacific, current: 'midknitepacific' })
  }

  midknitelatin(data) {
    this.stateInitialize()
    const midknitelatin = data.filter(
      e => e.areaCategory.indexOf('midknitelatin') !== -1
    )
    this.setState({ stateData: midknitelatin, current: 'midknitelatin' })
  }

  midkniteafrica(data) {
    this.stateInitialize()
    const midkniteafrica = data.filter(
      e => e.areaCategory.indexOf('midkniteafrica') !== -1
    )
    this.setState({ stateData: midkniteafrica, current: 'midkniteafrica' })
  }

  midknitejapan(data) {
    this.stateInitialize()
    const midknitejapan = data.filter(
      e => e.areaCategory.indexOf('midknitejapan') !== -1
    )
    this.setState({ stateData: midknitejapan, current: 'midknitejapan' })
  }

  midkniteblue(data) {
    this.stateInitialize()
    const midkniteblue = data.filter(
      e => e.areaCategory.indexOf('midkniteblue') !== -1
    )
    this.setState({ stateData: midkniteblue, current: 'midkniteblue' })
  }

  bleudetokyo(data) {
    this.stateInitialize()
    const bleudetokyo = data.filter(
      e => e.areaCategory.indexOf('bleudetokyo') !== -1
    )
    this.setState({ stateData: bleudetokyo, current: 'bleudetokyo' })
  }

  year(data, year) {
    this.stateInitialize()
    const selectedyear = data.filter(
      e =>
        e.date.start.indexOf(year) !== -1 ||
        (e.date.end && e.date.end.indexOf(year) !== -1)
    )
    this.setState({ stateData: selectedyear, current: year })
  }

  activeClass(category) {
    const { current } = this.state
    if (category === current) {
      return 'current'
    }
    return null
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const {
      stateData,
      current,
      // toggle,
      // text,
      // midkniteeuro,
      // midkniteusa,
      // midkniteasia,
      // midknitepacific,
      // midknitelatin,
      // midkniteafrica,
      // midknitejapan,
      // midkniteblue,
      noMatch,
      page,
    } = this.state

    keepState = current

    const { data } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    selectedData = _.orderBy(selectedData, ['generalId'], ['asc'])
    // const reverseData = selectedData.concat()

    selectedData = selectedData.filter(
      e => e.dependency !== 'parent' && e.showSchedule !== 'hidden'
    )

    const dataLength = selectedData.length
    const perPage = 50
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      selectedData = selectedData.slice(perPage * (page - 1), perPage * page)
    }
    if (dataLength === 0)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )

    const year = new Date().getFullYear()
    const years = []
    for (let i = 2001; i <= year; i++) {
      years.push(i)
    }

    return (
      <>
        <div className="tourCategory">
          <Button
            className={current === 'all' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.all(data)
            }}
          >
            ALL
          </Button>
          <Button
            className={current === 'international' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.international(data)
            }}
          >
            INTERNATIONAL
          </Button>
          <Button
            className={current === 'domestic' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.domestic(data)
            }}
          >
            DOMESTIC
          </Button>
          <Button
            className={current === 'event' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.event(data)
            }}
          >
            EVENT
          </Button>
          <Button
            className={current === 'midkniteathletics' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midkniteathletics(data)
            }}
          >
            <MkxIcon />
            <span style={{ marginLeft: '5px' }}>MID KNITE ATHLETICS</span>
          </Button>
          <Button
            className={current === 'midknitetreks' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midknitetreks(data)
            }}
          >
            <MktIcon />
            <span style={{ marginLeft: '5px' }}>MID KNITE TREKS</span>
          </Button>
          <Button
            className={current === 'humankind' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.humankind(data)
            }}
          >
            <HmkIcon />
            <span style={{ marginLeft: '5px' }}>HUMANKIND</span>
          </Button>
          <Button
            className={current === 'madeinworld' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.madeinworld(data)
            }}
          >
            <MiwIcon />
            <span style={{ marginLeft: '5px' }}>MADE IN WORLD</span>
          </Button>
          {/* {countries.map(countryName => {
            return (
              <Button
                key={countryName}
                className={current === countryName ? 'current' : ''}
                variant="outlined"
                onClick={() => {
                  this.country(data, countryName)
                }}
              >
                {countryName}
              </Button>
            )
          })} */}
          <Button
            className={current === 'jht' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.jht(data)
            }}
          >
            <JhtIcon />
            <span style={{ marginLeft: '5px' }}>
              JOHNAN HIGHSCHOOL TRAVELLERS
            </span>
          </Button>
          <Button
            className={current === 'madeinmejiro' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.madeinmejiro(data)
            }}
          >
            <MimIcon />
            <span style={{ marginLeft: '5px', textTransform: 'none' }}>
              Made in Mejiro
            </span>
          </Button>
          <Button
            className={current === 'newmankind' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.newmankind(data)
            }}
          >
            NEWMANKIND
          </Button>
          <Button
            className={current === 'marinday' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.marinday(data)
            }}
          >
            <span style={{ textTransform: 'none' }}>
              Marin Day
            </span>
          </Button>
          <Button
            className={current === 'nanione' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.nanione(data)
            }}
          >
            <span style={{ textTransform: 'none' }}>
              NaniOne
            </span>
          </Button>
        </div>
        <div className="accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>MID KNITE JAPAN INTERNATIONAL NETWORK</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: '8px 12px',
              }}
            >
              <div className="tourCategoryMedium">
                <Button
                  size="small"
                  className={
                    current === 'midkniteeuro'
                      ? 'midkniteeuro current'
                      : 'midkniteeuro'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midkniteeuro(data)
                  }}
                >
                  MID KNITE €URO
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midkniteusa'
                      ? 'midkniteusa current'
                      : 'midkniteusa'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midkniteusa(data)
                  }}
                >
                  MID KNITE U$A
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midkniteasia'
                      ? 'midkniteasia current'
                      : 'midkniteasia'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midkniteasia(data)
                  }}
                >
                  MID KNITE ASIA
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midknitepacific'
                      ? 'midknitepacific current'
                      : 'midknitepacific'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midknitepacific(data)
                  }}
                >
                  MID KNITE PACIFIC
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midkniteafrica'
                      ? 'midkniteafrica current'
                      : 'midkniteafrica'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midkniteafrica(data)
                  }}
                >
                  MID KNITE AFRICA
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midknitelatin'
                      ? 'midknitelatin current'
                      : 'midknitelatin'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midknitelatin(data)
                  }}
                >
                  MID KNITE LATIN
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midknitejapan'
                      ? 'midknitejapan current'
                      : 'midknitejapan'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midknitejapan(data)
                  }}
                >
                  MID KNITE JAPAN
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'midkniteblue'
                      ? 'midkniteblue current'
                      : 'midkniteblue'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.midkniteblue(data)
                  }}
                >
                  MID KNITE BLUE
                </Button>
                <Button
                  size="small"
                  className={
                    current === 'bleudetokyo'
                      ? 'bleudetokyo current'
                      : 'bleudetokyo'
                  }
                  variant="outlined"
                  onClick={() => {
                    this.bleudetokyo(data)
                  }}
                >
                  BLEU DE TOKYO
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>YEAR</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: '8px 12px',
              }}
            >
              <div className="tourCategoryMedium">
                {years.map(e => {
                  return (
                    <Button
                      key={e}
                      size="small"
                      className={current === e ? `y${e} current` : 'year'}
                      variant="outlined"
                      onClick={() => {
                        this.year(data, e)
                      }}
                    >
                      {e}
                    </Button>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {noMatch ? (
          <div>NO DATA MATCHED</div>
        ) : (
          <ul className="list">
            {selectedData.map(e => {
              const link = `/alltimeschedule/${e.slug}`
              const tags = e.parentCategory.map(c => c.toUpperCase())
              if (
                e.dependency === 'parent' ||
                e.showSchedule === 'hidden' ||
                (e.show === 'hidden' && e.showSchedule === 'hidden')
              )
                return null
              if (e.date.end) {
                return (
                  <li key={e.tourId}>
                    <span className="date">
                      <div className="tagBox">
                        {tags.map((tag, i) => {
                          return (
                            <span
                              key={tag}
                              className={`tag ${e.parentCategory[i]}`}
                            >
                              {tag}
                            </span>
                          )
                        })}
                      </div>
                      {e.date.start}〜{e.date.end}
                    </span>
                    {e.title.startsWith('JHT') ? (
                      <Link to={link} className="withIcon">
                        <JhtIcon />
                        <span>{e.title.substr(3)}</span>
                      </Link>
                    ) : (
                      <Link to={link}>{e.title}</Link>
                    )}
                  </li>
                )
              }
              return (
                <li key={e.tourId}>
                  <span className="date">
                    <div className="tagBox">
                      {tags.map((tag, i) => {
                        return (
                          <span
                            key={tag}
                            className={`tag ${e.parentCategory[i]}`}
                          >
                            {tag}
                          </span>
                        )
                      })}
                    </div>
                    {e.date.start}
                  </span>
                  {e.title.startsWith('JHT') ? (
                    <Link to={link} className="withIcon">
                      <JhtIcon />
                      <span>{e.title.substr(3)}</span>
                    </Link>
                  ) : (
                    <Link to={link}>{e.title}</Link>
                  )}
                </li>
              )
            })}
          </ul>
        )}
        <Pagination
          count={pageNumber}
          className="pagination"
          page={page}
          onChange={(e, value) => {
            this.handlePage(e, value)
          }}
        />
      </>
    )
  }
}

export default withRouter(ScheduleList)

ScheduleList.propTypes = {
  data: PropTypes.array,
}

ScheduleList.defaultProps = {
  data: null,
}
