import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import GridOnIcon from '@material-ui/icons/GridOn'
import PropTypes from 'prop-types'
// import FormLabel from '@material-ui/core/FormLabel'
// import FormControl from '@material-ui/core/FormControl'
// import FormGroup from '@material-ui/core/FormGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
// import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CircularProgress from '@material-ui/core/CircularProgress'
import HmkIcon from '../icons/HmkIcon'
import MkxIcon from '../icons/MkxIcon'
import MktIcon from '../icons/MktIcon'
import MiwIcon from '../icons/MiwIcon'

let keepState = ''
let keepCountry = ''
let keepNet = ''
let keepToggleState = false
let keepPage = ''

const END_POINT = '/data/countries.json'
const END_POINT2 = '/data/network.json'

class TourList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      toggle: keepToggleState || false,
      text: 'change style to list',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midkniterouge: true,
      midknitejapan: true,
      midkniteblue: true,
      midknitetokyo: true,
      midknitemetro: true,
      midkniteozone: true,
      midkniteone: true,
      midknitecentral: true,
      midkniteviolet: true,
      midknitediamond: true,
      midknitemichinok: true,
      midknitesunrise: true,
      bleudetokyo: true,
      noMatch: false,
      countries: [],
      network: [],
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ countries: result.data })
    })
    axios.get(END_POINT2).then(result => {
      this.setState({ network: result.data })
    })
    const { data } = this.props
    if (data.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
      this.setState({
        page: 1,
      })
      keepPage = ''
    }
    if (keepState === 'country') {
      this.country(data, keepCountry)
    } else if (keepState === 'network') {
      this.network(data, keepNet)
    } else if (keepState !== '') {
      this[keepState](data)
    }
    if (keepToggleState) {
      this.setState({
        toggle: true,
      })
    }
    if (keepPage !== '') {
      this.setState({
        page: keepPage,
      })
    }
  }

  handleChange = event => {
    const {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midkniterouge,
      midknitejapan,
      midkniteblue,
      midknitemetro,
      midknitetokyo,
      midkniteozone,
      midkniteone,
      midknitecentral,
      midkniteviolet,
      midknitediamond,
      midknitemichinok,
      midknitesunrise,
      bleudetokyo
    } = this.state
    const { data } = this.props
    const name = event.currentTarget.value
    const checked = event.target.checked
    this.setState({
      current: 'all',
      [name]: checked,
    })
    const selectedCategory = {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midkniterouge,
      midknitejapan,
      midkniteblue,
      midknitemetro,
      midknitetokyo,
      midkniteozone,
      midkniteone,
      midknitecentral,
      midkniteviolet,
      midknitediamond,
      midknitemichinok,
      midknitesunrise,
      bleudetokyo,
      [name]: checked,
    }

    Object.keys(selectedCategory).forEach(key => {
      if (selectedCategory[key] === false) delete selectedCategory[key]
    })
    const categories = Object.keys(selectedCategory)

    const matchData = e => {
      const targetArrays = [...categories, ...e.areaCategory]
      const match = targetArrays.filter(
        item => categories.includes(item) && e.areaCategory.includes(item)
      )
      return match.length
    }

    const selectedData = data.filter(e => matchData(e) !== 0)

    // カテゴリー一個に対してだけなら下記でもOK
    // const selectedData = data.filter(
    //   e => categories.indexOf(e.areaCategory[0]) !== -1
    // )
    if (selectedData.length === 0) {
      this.setState({ noMatch: true })
    } else {
      this.setState({
        stateData: selectedData,
        noMatch: false,
      })
    }
  }

  toggleStyle = () => {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
      keepToggleState = false
    } else {
      this.setState({
        text: 'change style to table',
      })
      keepToggleState = true
    }
  }

  removeAllCheck = () => {
    this.setState({
      midkniteeuro: false,
      midkniteusa: false,
      midkniteasia: false,
      midknitepacific: false,
      midknitelatin: false,
      midkniteafrica: false,
      midkniterouge: false,
      midknitejapan: false,
      midkniteblue: false,
      midknitemetro: false,
      midknitetokyo: false,
      midkniteozone: false,
      midkniteone: false,
      midknitecentral: false,
      midkniteviolet: false,
      midknitediamond: false,
      midknitemichinok: false,
      midknitesunrise: false,
      bleudetokyo: false,
      noMatch: true,
    })
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midkniterouge: true,
      midknitejapan: true,
      midkniteblue: true,
      midknitemetro: true,
      midknitetokyo: true,
      midkniteozone: true,
      midkniteone: true,
      midknitecentral: true,
      midkniteviolet: true,
      midknitediamond: true,
      midknitemichinok: true,
      midknitesunrise: true,
      bleudetokyo: true,
      noMatch: false,
      page: 1,
    })
  }

  all(data) {
    this.stateInitialize()
    this.setState({ stateData: data })
  }

  international(data) {
    this.stateInitialize()
    const international = data.filter(e =>
      e.parentCategory.includes('international')
    )
    this.setState({ stateData: international, current: 'international' })
  }

  domestic(data) {
    this.stateInitialize()
    const domestic = data.filter(e => e.parentCategory.includes('domestic'))
    this.setState({ stateData: domestic, current: 'domestic' })
  }

  event(data) {
    this.stateInitialize()
    const event = data.filter(e => e.parentCategory[0] === 'event')
    this.setState({ stateData: event, current: 'event' })
  }

  midkniteathletics(data) {
    this.stateInitialize()
    const midkniteathletics = data.filter(
      e => e.brandCategory && e.brandCategory.includes('midkniteathletics')
    )
    this.setState({
      stateData: midkniteathletics,
      current: 'midkniteathletics',
    })
  }

  midknitetreks(data) {
    this.stateInitialize()
    const midknitetreks = data.filter(
      e => e.brandCategory && e.brandCategory.includes('midknitetreks')
    )
    this.setState({ stateData: midknitetreks, current: 'midknitetreks' })
  }

  humankind(data) {
    this.stateInitialize()
    const humankind = data.filter(
      e => e.brandCategory && e.brandCategory.includes('humankind')
    )
    this.setState({ stateData: humankind, current: 'humankind' })
  }

  newmankind(data) {
    this.stateInitialize()
    const newmankind = data.filter(
      e => e.brandCategory && e.brandCategory.includes('newmankind')
    )
    this.setState({ stateData: newmankind, current: 'newmankind' })
  }

  marinday(data) {
    this.stateInitialize()
    const marinday = data.filter(
      e => e.brandCategory && e.brandCategory.includes('marinday')
    )
    this.setState({ stateData: marinday, current: 'marinday' })
  }

  grandday(data) {
    this.stateInitialize()
    const grandday = data.filter(
      e => e.brandCategory && e.brandCategory.includes('grandday')
    )
    this.setState({ stateData: grandday, current: 'grandday' })
  }

  nanione(data) {
    this.stateInitialize()
    const nanione = data.filter(
      e => e.brandCategory && e.brandCategory.includes('nanione')
    )
    this.setState({ stateData: nanione, current: 'nanione' })
  }

  ambivalentum(data) {
    this.stateInitialize()
    const ambivalentum = data.filter(
      e => e.brandCategory && e.brandCategory.includes('ambivalentum')
    )
    this.setState({ stateData: ambivalentum, current: 'ambivalentum' })
  }

  polaris(data) {
    this.stateInitialize()
    const polaris = data.filter(
      e => e.brandCategory && e.brandCategory.includes('polaris')
    )
    this.setState({ stateData: polaris, current: 'polaris' })
  }

  knoks(data) {
    this.stateInitialize()
    const knoks = data.filter(
      e => e.brandCategory && e.brandCategory.includes('knoks')
    )
    this.setState({ stateData: knoks, current: 'knoks' })
  }

  risin(data) {
    this.stateInitialize()
    const risin= data.filter(
      e => e.brandCategory && e.brandCategory.includes('risin')
    )
    this.setState({ stateData: risin, current: 'risin' })
  }

  madeinworld(data) {
    this.stateInitialize()
    const madeinworld = data.filter(
      e => e.brandCategory && e.brandCategory.includes('madeinworld')
    )
    this.setState({ stateData: madeinworld, current: 'madeinworld' })
  }

  country(data, countryName) {
    this.stateInitialize()
    const country = data.filter(
      e => e.countryCategory && e.countryCategory.includes(countryName)
    )
    this.setState({ stateData: country, current: countryName })
  }

  network(data, networkName) {
    this.stateInitialize()
    const network = data.filter(
      e => e.areaCategory && e.areaCategory.includes(networkName)
    )
    this.setState({ stateData: network, current: networkName })
  }

  activeClass(category) {
    const { current } = this.state
    if (category === current) {
      return 'current'
    }
    return null
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const {
      stateData,
      current,
      toggle,
      text,
      // midkniteeuro,
      // midkniteusa,
      // midkniteasia,
      // midknitepacific,
      // midknitelatin,
      // midkniteafrica,
      // midknitejapan,
      // midkniteblue,
      noMatch,
      countries,
      network,
      page,
    } = this.state

    if (countries.includes(current)) {
      keepState = 'country'
      keepCountry = current
    } else {
      keepState = current
    }
    network.forEach(e => {
      if (e.slug.includes(current)) {
        keepState = 'network'
        keepNet = current
      }
    })
    const { data } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const allData = _.orderBy(selectedData, ['generalId'], ['desc'])
    // const allData = selectedData.concat().reverse()
    let reverseData = allData.filter(e => e !== undefined || e !== null)
    reverseData = reverseData.filter(
      e => e && e.dependency !== 'child' && e.show !== 'hidden'
    )

    const dataLength = reverseData.length
    const perPage = 20
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      reverseData = reverseData
        .slice(perPage * (page - 1), perPage * page)
        .reverse()
    } else {
      reverseData = reverseData.reverse()
    }
    if (dataLength === 0) {
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    }

    return (
      <div>
        <div className="tourCategory">
          <Button
            className={current === 'all' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.all(data)
            }}
          >
            ALL
          </Button>
          <Button
            className={current === 'international' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.international(data)
            }}
          >
            INTERNATIONAL
          </Button>
          <Button
            className={current === 'domestic' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.domestic(data)
            }}
          >
            DOMESTIC
          </Button>
          <Button
            className={current === 'event' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.event(data)
            }}
          >
            EVENT
          </Button>
          <Button
            className={current === 'midkniteathletics' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midkniteathletics(data)
            }}
          >
            <MkxIcon />
            <span style={{ marginLeft: '5px' }}>MID KNITE ATHLETICS</span>
          </Button>
          <Button
            className={current === 'midknitetreks' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midknitetreks(data)
            }}
          >
            <MktIcon />
            <span style={{ marginLeft: '5px' }}>MID KNITE TREKS</span>
          </Button>
          <Button
            className={current === 'humankind' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.humankind(data)
            }}
          >
            <HmkIcon />
            <span style={{ marginLeft: '5px' }}>HUMANKIND</span>
          </Button>
          <Button
            className={current === 'newmankind' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.newmankind(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span>NEWMANKIND</span>
          </Button>
          <Button
            className={current === 'marinday' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.marinday(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ textTransform: 'none' }}>Marin Day</span>
          </Button>
          <Button
            className={current === 'grandday' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.grandday(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ textTransform: 'none' }}>Grand Day</span>
          </Button>
          <Button
            className={current === 'nanione' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.nanione(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ textTransform: 'none' }}>NaniOne</span>
          </Button>
          <Button
            className={current === 'ambivalentum' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.ambivalentum(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ textTransform: 'none' }}>Ambivalentum</span>
          </Button>
          <Button
            className={current === 'polaris' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.polaris(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ textTransform: 'none' }}>Polaris</span>
          </Button>
          <Button
            className={current === 'knoks' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.knoks(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ marginLeft: '5px' }}>KN♂KS</span>
          </Button>
          <Button
            className={current === 'risin' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.risin(data)
            }}
          >
            {/* <HmkIcon /> */}
            <span style={{ marginLeft: '5px' }}>RISIN'</span>
          </Button>
          <Button
            className={current === 'madeinworld' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.madeinworld(data)
            }}
          >
            <MiwIcon />
            <span style={{ marginLeft: '5px' }}>MADE IN WORLD</span>
          </Button>
        </div>
        <Button
          size="small"
          className="toggleButton"
          // variant="contained"
          onClick={this.toggleStyle}
        >
          {toggle ? <GridOnIcon /> : <ListIcon />}
          {text}
        </Button>

        {/* <div className="accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>CUSTOMIZE CONDITIONS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="checkBox">
                <div className="handleCheckButton">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<CheckBoxIcon />}
                    onClick={() => {
                      this.all(data)
                    }}
                  >
                    Check all conditions
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<IndeterminateCheckBoxIcon />}
                    onClick={this.removeAllCheck}
                  >
                    Remove all conditions
                  </Button>
                </div>
                <FormControl component="fieldset" className="formControl">
                  <FormLabel component="legend">
                    Display Tours that the conditon is matched
                  </FormLabel>
                  <FormGroup className="formGroup">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteeuro}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteeuro"
                        />
                      }
                      label="MID KNITE €URO"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteusa}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteusa"
                        />
                      }
                      label="MID KNITE U$A"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteasia}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteasia"
                        />
                      }
                      label="MID KNITE ASIA"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitepacific}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitepacific"
                        />
                      }
                      label="MID KNITE PACIFIC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitelatin}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitelatin"
                        />
                      }
                      label="MID KNITE LATIN"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteafrica}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteafrica"
                        />
                      }
                      label="MID KNITE AFRICA"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitejapan}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitejapan"
                        />
                      }
                      label="MID KNITE JAPAN"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteblue}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteblue"
                        />
                      }
                      label="MID KNITE BLUE"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}

        <div className="accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>MID KNITE JAPAN INTERNATIONAL NETWORK</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="tourCategoryMedium">
                {network.map(networkName => {
                  return (
                    <Button
                      key={networkName.slug}
                      className={
                        current === networkName.slug
                          ? `current ${networkName.slug}`
                          : networkName.slug
                      }
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        this.network(data, networkName.slug)
                      }}
                    >
                      {networkName.name}
                    </Button>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordion">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>COUNTRIES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="tourCategorySmall">
                {countries.map(countryName => {
                  return (
                    <Button
                      key={countryName}
                      className={current === countryName ? 'current' : ''}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        this.country(data, countryName)
                      }}
                    >
                      <img
                        src={`/images/flags/${countryName
                          .toLowerCase()
                          .replace(/\s+/g, '')}.png`}
                        alt={countryName}
                        className="scheduleFlag"
                      />
                      {countryName}
                    </Button>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {noMatch ? (
          <div>NO DATA MATCHED</div>
        ) : (
          <ul className={`list archive${toggle ? ' listStyle' : ''}`}>
            {reverseData.reverse().map((e, i) => {
              const link = `/tour/${e.slug}`
              const mainVisual = e.mainVisualUrl ? e.mainVisualUrl[0] : ''
              if (e.dependency === 'child' || e.show === 'hidden') return null
              return (
                <li key={i}>
                  <span className="date">
                    {e.parentCategory.map(el => {
                      return (
                        <span className={`tag ${el}`}>{el.toUpperCase()}</span>
                      )
                    })}
                  </span>
                  <Link to={link}>
                    <div className="thumbnail">
                      <img src={mainVisual} alt={e.title} />
                    </div>
                    {e.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
        <Pagination
          count={pageNumber}
          className="pagination"
          page={page}
          onChange={(e, value) => {
            this.handlePage(e, value)
          }}
        />
      </div>
    )
  }
}

export default withRouter(TourList)

TourList.propTypes = {
  data: PropTypes.array,
}

TourList.defaultProps = {
  data: null,
}
